.Home {
    background-color: #ffffff;
    margin-top: 4vh;
    text-align: center;
}

.Home-logo {
    width: 16%;
    min-width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.Home-content {
    text-align: center;
    margin-left: 4vw;
}

.contact-button {
    background-color: black;
    border: none;
    border-radius: 0.4rem;
    color: white;
    padding: 0.6rem 1.28rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
}