.Footer {
    background-color: #000000;
    color: #ffffff;
    text-align: left;
    width: 100%;
    bottom: 0;
    position: fixed;
}

.Footer-logo {
    width: 16%;
    min-width: 100px;
}

.Footer-copy {
    float: right;
    margin-right: 20px;
}

.Footer-reg {
    font-size: 0.5rem;
}